<template>
  <div class="products">
    <div class="container">
      <h5>Products</h5>
      <div class="row">
        <div class="col s12 m4" v-for="n in 12" :key="n">
          <div class="card">
            <div class="card-image">
              <img src="@/assets/vectors/bg.webp" />
              <span class="card-title">Product name</span>
            </div>
            <div class="card-content">
              <p>
                I am a very simple card. I am good at containing small bits of
                information.
              </p>
            </div>
            <!-- <div class="card-action">
              <a href="#">This is a link</a>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, computed, reactive } from "vue";
import { useHead } from "@vueuse/head";

export default defineComponent({
  props: [],
  setup() {
    const siteData = reactive({
      title: `E commerce | products`,
      description: `My beautiful website`,
    });

    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description),
        },
      ],
    });
  },
});
</script>
<style>
.products {
  min-height: 100vh;
  margin-top: 55px;
  margin-bottom: 55px;
}
</style>
